import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ProductService } from 'src/app/shared/services/product.service';
import { Product } from '../../../shared/classes/product';

@Component({
  selector: 'app-header-variation-filter',
  templateUrl: './header-variation-filter.component.html',
  styleUrls: ['./header-variation-filter.component.scss']
})
export class HeaderVariationFilterComponent implements OnInit {
  @Input() products: Product[] = [];
  @Input() customVariation: any[] = [];
  @Input() category: string;
  @Output() customVariationFilter: EventEmitter<any> = new EventEmitter<any>();

  public collapse: boolean = true;
  public filter: any;
  constructor(private productService: ProductService) {
    this.productService.getProductsVariation.subscribe(fil => this.filter = fil);
  }

  ngOnInit(): void {
  }

  get filterbyVariation() {
    //const uniqueVariaion = [...new Set(this.filter?.Brands.map(b => b.ItemCompanyName))];
    return this.filter;
  }

  appliedFilter(event) {
    const index = this.customVariation.indexOf(event.target.value);  // checked and unchecked value
    if (event.target.checked) {
      this.customVariation.push(event.target.value);
    } // push in array cheked value
    else {
      this.customVariation.splice(index, 1);
    }  // removed in array unchecked value  

    const vari = this.customVariation.length ? { brand: this.customVariation.join(',') } : { brand: null };
    this.customVariationFilter.emit(vari);
  }

  // check if the item are selected
  checked(item) {
    if (this.customVariation.indexOf(item) !== -1) {
      return true;
    }
  }

}
