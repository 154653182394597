<!-- <div class="collection-collapse-block" [class.open]="collapse"
    *ngFor="let grp of variation.Child | groupBy: 'ProductType' | keyvalue; let i = index">
    <h3 class="collapse-block-title" (click)="collapse = !collapse" [textContent]="grp.key | translate"></h3>
    <div class="collection-collapse-block-content">
      <div class="collection-brand-filter">
        <div class="custom-control custom-checkbox collection-filter-checkbox"
          *ngFor="let vari of grp.value; let i = index">
          <input type="checkbox" [value]="vari.ProductSubType" [checked]="checked(vari.ProductSubType)"
            [id]="vari.ProductSubType" (change)="appliedFilter($event)" class="custom-control-input" />
          <label class="custom-control-label" for="{{ vari.ProductSubType }}">{{ vari.ProductSubType }}</label>
        </div>
      </div>

    </div>
  </div> -->
<!-- <ul class="nav-menu">
  <ng-container *ngFor="let variation of filterbyVariation; let i = index">
   
    <li *ngFor="let grp of variation.Child | groupBy: 'ProductType' | keyvalue; let i = index"
     >
     <ng-container  *ngFor="let vari of grp.value; let i = index">
      {{ vari.ProductSubType }}
    </ng-container>
    </li>
  </ng-container>

</ul> -->
ssss
<ul class="dropdown-menu">
  <li *ngFor="let variation of filterbyVariation; let i = index;" [ngClass]="{'dropdown-submenu':variation.Child.length>0}"><a tabindex="-1" href="#">{{variation.Name}}</a></li>
  <li><a tabindex="-1" href="#">CSS</a></li>
  <li class="dropdown-submenu">
    <a class="test" tabindex="-1" href="#">New dropdown <span class="caret"></span></a>
    <ul class="dropdown-menu">
      <li><a tabindex="-1" href="#">2nd level dropdown</a></li>
      <li><a tabindex="-1" href="#">2nd level dropdown</a></li>
      <li class="dropdown-submenu">
        <a class="test" href="#">Another dropdown <span class="caret"></span></a>
        <ul class="dropdown-menu">
          <li><a href="#">3rd level dropdown</a></li>
          <li><a href="#">3rd level dropdown</a></li>
        </ul>
      </li>
    </ul>
  </li>
</ul>