<!-- side-bar single product slider start -->
<div class="theme-card">
    <h5 class="title-border">{{ title }}</h5>
    <owl-carousel-o [options]="NewProductSliderConfig" class="offer-slider">
        <ng-container *ngFor="let product of products | slice:0:3 ">  
            <ng-template carouselSlide>
                <div>
                  <div class="d-flex align-items-center" *ngFor="let product of products | slice:0:3 ">
                    <a [routerLink]="['/products/', product.ItemName.replace(' ', '-')]">
                      <img class="img-fluid w-auto" *ngIf="!isVideoType(product.ImgName?.src)"
                           [defaultImage]="'assets/images/product/placeholder.jpg'" 
                           [lazyLoad]="product.ImgName?.src" alt="">
                           <video class="img-fluid" autoplay loop muted *ngIf="isVideoType(product.ImgName?.src)">
                            <source [src]="'https://lendensoft.s3.ap-northeast-1.amazonaws.com/'+product.ImgName?.src" type="video/mp4" />
                      </video>     
                    </a>
                    <div class="media-body align-self-center">
                      <!-- <bar-rating [rate]="5" [readOnly]="true"></bar-rating> -->
                      <a [routerLink]="['/products/', product.ItemName.replace(' ', '-')]">
                      <h6 translate>{{ product.ItemName | titlecase }}</h6></a>
                      <h4 translate>
                        {{ product?.SellRate * productService.Currency?.price | discount:product | currency:productService.Currency?.currency:'symbol' }}
                        <del *ngIf="product?.discount">
                          <span class="money"> 
                            {{ product?.SellRate * productService.Currency?.price | currency:productService.Currency?.currency:'symbol' }}
                          </span>
                        </del>
                      </h4>
                    </div>
                  </div>
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div>
                  <div class="d-flex align-items-center" *ngFor="let product of products | slice:3:6 ">
                    <a [routerLink]="['/products/', product.ItemName.replace(' ', '-')]">
                      <img class="img-fluid w-auto"  *ngIf="!isVideoType(product.ImgName?.src)"
                           [defaultImage]="'assets/images/product/placeholder.jpg'" 
                           [lazyLoad]="product.ImgName?.src" alt="">
                      <video class="img-fluid" autoplay loop muted *ngIf="isVideoType(product.ImgName?.src)">
                            <source [src]="'https://lendensoft.s3.ap-northeast-1.amazonaws.com/'+product.ImgName?.src" type="video/mp4" />
                      </video>     
                    </a>
                    <div class="media-body align-self-center">
                      <!-- <bar-rating [rate]="5" [readOnly]="true"></bar-rating> -->
                      <a [routerLink]="['/products/', product.ItemName.replace(' ', '-')]">
                      <h6 translate>{{ product.ItemName | titlecase }}</h6></a>
                      <h4 translate>
                        {{ product?.SellRate * productService.Currency?.price | discount:product | currency:productService.Currency?.currency:'symbol' }}
                        <del *ngIf="product?.discount">
                          <span class="money"> 
                            {{ product?.SellRate * productService.Currency?.price | currency:productService.Currency?.currency:'symbol' }}
                          </span>
                        </del>
                      </h4>
                    </div>
                  </div>
                </div>
            </ng-template>
        </ng-container>
    </owl-carousel-o>
</div>
<!-- side-bar single product slider end -->
  