<div class="media d-flex align-items-center">
    <a [routerLink]="['/products/', product.ItemName.replace(' ', '-')]">
        <img [defaultImage]="ImageSrc ? ImageSrc : 'assets/images/product/placeholder.jpg'" 
             [lazyLoad]="ImageSrc ? ImageSrc : product.ImgName?.src"  
             class="img-fluid lazy-loading" alt="{{ product.ImgName?.alt }}">
    </a>
	<div class="media-body align-self-center">
		<div class="rating">
			<bar-rating [rate]="5" [readOnly]="true"></bar-rating>
		</div>
        <a [routerLink]="['/products/', product.ItemName.replace(' ', '-')]">
            <h6 translate>{{ product.ItemName | titlecase }}</h6>
        </a>
		<h4 translate>{{ product?.SellRate * currency?.price | discount:product | currency:currency?.currency:'symbol' }}</h4>
        <ul class="color-variant">
            <li [class]="color" 
                *ngFor="let color of Color(product?.ItemFrontAttributes)" 
                [ngStyle]="{'background-color': color}"
                (click)="ChangeVariants(color, product)">
            </li>
        </ul>
	</div>
</div>