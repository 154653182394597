import { Component, OnInit, Input } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HomeSlider } from '../../../shared/data/slider';

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss']
})
export class SliderComponent implements OnInit {

  @Input() sliders: any[];
  @Input() class: string;
  @Input() textClass: string;
  @Input() category: string;
  @Input() buttonText: string;
  @Input() buttonClass: string;
  constructor() { }
  env = environment;
  ngOnInit(): void {
  }
  sendToUrl(url,buttonText){
    if(url || !buttonText){
      window.location.href=url;
    }
  }
  public HomeSliderConfig: any = HomeSlider;

}
