import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'variation'
})
export class VariationPipe implements PipeTransform {

  transform(list: any[], key: string, value: string): any {
    if(value && key){
      return list?.filter((i) => {
        return i[key]?.toLowerCase().replaceAll(' ','-') === value?.toLowerCase().replaceAll(' ','-')
      });
    }
   else{
    return list;
   }
  }

}