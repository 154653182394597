import { Injectable, HostListener } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

// Menu
export interface Menu {
	path?: string;
	title?: string;
	type?: string;
	megaMenu?: boolean;
	paramObj?: object;
	image?: string;
	active?: boolean;
	badge?: boolean;
	badgeText?: string;
	children?: Menu[];
}

@Injectable({
	providedIn: 'root'
})

export class NavService {
	constructor() { }
	public screenWidth: any;
	public leftMenuToggle: boolean = false;
	public mainMenuToggle: boolean = false;
	@HostListener('window:resize', ['$event'])
	onResize(event?) {
		this.screenWidth = window.innerWidth;
	}
	MENUITEMS: Menu[] = [
		{
			path: '/collections/all', title: 'Shop All', type: 'link'
		},
		{
			path: '/collections/giftvoucher', title: 'Gift Item', type: 'link'
		}
	];
	items = new BehaviorSubject<Menu[]>(this.MENUITEMS);
	leftMenuItems = new BehaviorSubject<Menu[]>([]);
	footerPageMenuItems = new BehaviorSubject<Menu[]>([]);
	updateLeftMenu(newItem) {		
		this.leftMenuItems.next(newItem);
	}
	updatePages(pages) {
		var headerPages = {};
		var footerPages;
		[pages].forEach((element) => {
			let hedchild=[];
			let footchild=[];
			element.children.forEach((subElement) => {
				if (subElement.PageType === 'Header') {
					hedchild.push(subElement);
				}
				if (subElement.PageType === 'Footer') {
					footchild.push(subElement);
				}
			});
			headerPages={title: 'pages', type: 'sub', active: false, children: hedchild}
			footerPages={title: 'pages', type: 'sub', active: false,  children: footchild}
		});
		this.items.value.push(headerPages);
		this.footerPageMenuItems.next(footerPages);
		this.items.next(this.items.value);
	}
}
