import { CookieService } from 'ngx-cookie-service';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, startWith, delay } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { ItemFrontAttribute, Product } from '../classes/product';
import { environment } from 'src/environments/environment';
import { Menu, NavService } from './nav.service';
import * as _ from 'lodash';
import { CustomerService } from './customer.service';
import { CartService } from './cart.service';

const state = {
  products: JSON.parse(localStorage.products || '[]'),
  itemsfilter: JSON.parse(localStorage.itemsfilter || '[]'),
  ItemsVariationFilter: JSON.parse(localStorage.ItemsVariationFilter || '[]'),
  wishlist: JSON.parse(localStorage.wishlistItems || '[]'),
  compare: JSON.parse(localStorage.compareItems || '[]'),
  cart: JSON.parse(localStorage.cartItems || '[]')
};

@Injectable({
  providedIn: 'root'
})
export class ProductService {
  env = environment;
  public Currency = { name: 'Rupees', currency: 'INR', price: 1 }; // Default Currency
  public OpenCart: boolean = false;
  public Products;
  public ItemsFilter;
  public ItemsTag;
  public ItemsVariationFilter;
  public sideBarProducts;
  constructor(private http: HttpClient, private cartService: CartService,
    private toastrService: ToastrService, private customerService: CustomerService, private cookieService: CookieService, private navServices: NavService) {

    this.getAbandonCart('12345').subscribe((res) => {

      try {
        localStorage.setItem('cartItems', res?.Data?.Detail || '[]');
        state.cart = JSON.parse(res?.Data?.Detail || []);
        this.cartService.cartChange(true);
      }
      catch {
        localStorage.setItem('cartItems', '[]');
        state.cart = JSON.parse(res?.Data?.Detail || []);
        this.cartService.cartChange(true);
      }
    });
  }

  /*
    ---------------------------------------------
    ---------------  Product  -------------------
    ---------------------------------------------
  */
  getheaders(): any {
    let httpOptions: any;
    if (this.cookieService.get('auth')) {
      const token = this.cookieService.get('auth');
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json; charset=utf-8',
          Authorization: ('Bearer ' + token),
        })
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json; charset=utf-8'
        })
      };
    }
    return httpOptions;
  }

  getFormheaders(): any {
    let httpOptions: any;
    if (this.cookieService.get('auth')) {
      var token = this.cookieService.get('auth');
      httpOptions = {
        headers: new HttpHeaders({
          'encType': 'multipart/form-data;',
          "Authorization": 'Bearer ' + token
        })
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'encType': 'multipart/form-data;'
        })
      };
    }
    return httpOptions;
  }

  resetProducts() {

  }
  // Product
  private get products(): Observable<Product[]> {
    let res = this.http.get<any>(this.env.apiUrl + 'api/ItemFront/GetItemFrontWithFilter',
      this.getheaders()).pipe(map((data: any) => data.Data));
    const itemTags = [];
    res.subscribe(next => {
      const groupByCategory = _.groupBy(next.Items, 'ItemCategoryName');

      let LEFTMENUITEMS: Menu[] = [];
      let subCategory = [];
     
       next.Items.forEach((item)=>{
        item.ItemTag.forEach((tag)=>{
          itemTags.push(tag);
        });
      });
      this.ItemsTag= _.uniqBy(itemTags,'ItemTagId');
      Object.keys(groupByCategory).forEach(catkey => {
        subCategory = [];
        //Category
        const groupByItemsSub = _.groupBy(groupByCategory[catkey], 'ItemSubCategoryName');

        Object.keys(groupByItemsSub).forEach(subcatkey => {
          let children = [];
          groupByItemsSub[subcatkey].forEach(element => {
            children.push(
              { path: '/products/' + element.ItemName.replaceAll(' ', '-'), title: element.ItemName, type: 'link' }
            );
          });
          let paramObj = {
            category: catkey
          };
          if (groupByItemsSub[subcatkey] && groupByItemsSub[subcatkey].length > 0) {
            Object.assign(paramObj, { subcategory: subcatkey })
            //  subCategory.push({ title: subcatkey, type: 'link', active: false, path: '/collection/', paramObj: subcatkey });
          }

          subCategory.push({ title: subcatkey, type: 'link', active: false, path: '/collections/', paramObj: subcatkey.replaceAll(' ','-') });
        });
        //Generate Category List
        LEFTMENUITEMS.push({ title: catkey, type: 'sub', megaMenu: true, active: false, children: subCategory, path: '/collections/' + catkey.replaceAll(' ','-') })

      });
      this.navServices.updateLeftMenu(LEFTMENUITEMS);
      localStorage.products = JSON.stringify(next.Items);
      localStorage.itemsfilter = JSON.stringify(next.ItemsFilter);
      localStorage.ItemsVariationFilter = JSON.stringify(next.ItemsVariationFilter);
    });
    this.sideBarProducts = res.pipe(map((data: any) => data.Items), startWith(JSON.parse(localStorage.products || '[]')));
    this.ItemsFilter = res.pipe(map((data: any) => data.ItemsFilter), startWith(JSON.parse(localStorage.itemsfilter || '[]')));
    this.ItemsVariationFilter = res.pipe(map((data: any) => data.ItemsVariationFilter), startWith(JSON.parse(localStorage.ItemsVariationFilter || '[]')));
    return this.Products = res.pipe(map((data: any) => data.Items), startWith(JSON.parse(localStorage.products || '[]')));
  }

  // Get Products
  public get getProducts(): Observable<Product[]> {
    return this.products;
  }
  public get getProductsFilter(): Observable<any> {
    return this.ItemsFilter;
  }
  public get getProductsVariation(): Observable<any> {
    return this.ItemsVariationFilter;
  }
  // Get Products By Slug
  public getProductBySlug(slug: string): Observable<Product> {

    return this.products.pipe(map(items => {
      return items.find((item: any) => {
        return item.ItemName.replaceAll(' ', '-').toLowerCase() === slug.replaceAll(' ', '-').toLowerCase();
      });
    }));
  }
  // Get Recent Items
  public recentItems(): Observable<any> {
    return this.http.get<any[]>(this.env.apiUrl + 'api/ItemCountFront/ItemFrontRecentPopular',
      this.getheaders());
  }

  public getRelatedItems(ItemId: number): Observable<any> {
    return this.http.get<any[]>(this.env.apiUrl + 'api/Item/GetItemReletedProduct?ItemId=' + ItemId,
      this.getheaders());
  }
  public getBlogs(): Observable<any> {
    return this.http.get<any[]>(this.env.apiUrl + 'api/Blog/BlogMasterFront',
      this.getheaders());
  }

  public getAbandonCart(publicId: string): Observable<any> {
    return this.http.get<any[]>(this.env.apiUrl + 'api/Cart/AbandonCart?publicId=1222',
      this.getheaders());
  }

  public getItemReview(ItemId: any): Observable<any> {
    return this.http.get<any[]>(this.env.apiUrl + 'api/ItemReview/ItemReview?ItemId='+ItemId,
      this.getheaders());
  }
  public getReviewDetails(ItemId: any): Observable<any> {
    return this.http.get<any[]>(this.env.apiUrl + 'api/ItemReview/ItemReviewDetails?ItemId='+ItemId,
      this.getheaders());
  }
  

  public setItemReview(req: any): Observable<any> {
    return this.http.post<any[]>(this.env.apiUrl + 'api/ItemReview/ItemReview',req,
      this.getFormheaders());
  }

  /*
  ---------------------------------------------
    ---------------  Wish List  -----------------
    ---------------------------------------------
 
  
  */
  // Get Wishlist Items
  public get wishlistItems(): Observable<Product[]> {
    const itemsStream = new Observable(observer => {
      observer.next(state.wishlist);
      observer.complete();
    });
    return itemsStream as Observable<Product[]>;
  }

  // Add to Wishlist
  public addToWishlist(product): any {
    const wishlistItem = state.wishlist.find(item => item.id === product.id);
    if (!wishlistItem) {
      state.wishlist.push({
        ...product
      });
      this.customerService.createWishList({
        Id: 0,
        ItemId: product.ItemId
      }).subscribe({});
    }
    this.toastrService.success('Product has been added in wishlist.');
    localStorage.setItem('wishlistItems', JSON.stringify(state.wishlist));
    return true;
  }
  public setWishList(products): any {
    state.wishlist = products;
    localStorage.setItem('wishlistItems', JSON.stringify(state.wishlist));
  }

  // Remove Wishlist items
  public removeWishlistItem(product: Product): any {
    const index = state.wishlist.indexOf(product);
    state.wishlist.splice(index, 1);
    localStorage.setItem('wishlistItems', JSON.stringify(state.wishlist));
    return true;
  }

  /*
    ---------------------------------------------
    -------------  Compare Product  -------------
    ---------------------------------------------
  */

  // Get Compare Items
  public get compareItems(): Observable<Product[]> {
    const itemsStream = new Observable(observer => {
      observer.next(state.compare);
      observer.complete();
    });
    return itemsStream as Observable<Product[]>;
  }

  // Add to Compare
  public addToCompare(product): any {
    const compareItem = state.compare.find(item => item.id === product.id);
    if (!compareItem) {
      state.compare.push({
        ...product
      });
    }
    this.toastrService.success('Product has been added in compare.');
    localStorage.setItem('compareItems', JSON.stringify(state.compare));
    return true;
  }

  // Remove Compare items
  public removeCompareItem(product: Product): any {
    const index = state.compare.indexOf(product);
    state.compare.splice(index, 1);
    localStorage.setItem('compareItems', JSON.stringify(state.compare));
    return true;
  }

  /*
    ---------------------------------------------
    -----------------  Cart  --------------------
    ---------------------------------------------
  */

  // Get Cart Items
  public get cartItems(): Observable<Product[]> {
    const itemsStream = new Observable(observer => {
      observer.next(state.cart);
      observer.complete();
    });
    return itemsStream as Observable<Product[]>;
  }

  // Add to Cart
  public addToCart(product): any {
    
    const cartItem = state.cart.find(item => item.ItemId === product.ItemId);
    const qty = product.quantity ? product.quantity : 1;
    const items = cartItem ? cartItem : product;
    const stock = this.calculateStockCounts(items, qty);

    if (!stock) { return false; }

    if (cartItem) {
      if (product && product.ItemFrontAttributes && product?.ItemFrontAttributes?.length > 0) {
        cartItem?.ItemFrontAttributes?.forEach((item) => {
          if ((item.attrId || item.AttrId) === (product.attrId || product.AttrId)) {
            item.Selected = true;
            item.quantity = (item.quantity || 0) + parseInt(qty);
          }
        }, this);

      } else {
          const changeqty=(cartItem.quantity || 0) + parseInt(qty);
          if(changeqty<product.MinOrderQty){
            this.toastrService.info('Minimum Qty Require','Info');
            return;
          }
          if(changeqty>product.MaxOrderQty){
            this.toastrService.info('Max Qty Reached','Info');
            return;
          }
          cartItem.quantity = (cartItem.quantity || 0) + parseInt(qty);
      }
    } else {
      state.cart.push({
        ...product,
        quantity: (qty)
      });
    }

    this.OpenCart = true; // If we use cart variation modal
    localStorage.setItem('cartItems', JSON.stringify(state.cart));

    this.cartService.cartChange(true);
    return true;
  }

  // Update Cart Quantity
  public updateCartQuantity(product: Product, quantity: number): Product | boolean {
    if(product.ItemFrontAttributes && product.ItemFrontAttributes.length===0){
      if(product.quantity<product.MinOrderQty){
        this.toastrService.info('Minimum Qty Require','Info');
        return;
      }
      if(product.quantity>product.MaxOrderQty){
        this.toastrService.info('Max Qty Reached','Info');
        return;
      }
    }
    return state.cart.find((items, index) => {
      if (items.ItemId === product.ItemId && ((items.attrId || items.AttrId) === (product['attrId'] || product['AttrId']))) {
        const qty = state.cart[index].quantity + quantity;
        const stock = this.calculateStockCounts(state.cart[index], quantity);
        if (parseInt(qty) !== 0 && stock) {
          state.cart[index].quantity = qty;
        }
        localStorage.setItem('cartItems', JSON.stringify(state.cart));
        this.cartService.cartChange(true);
        return true;
      }
    });
  }
  // Update Attr Cart Quantity
  public updateAttrCartQuantity(product: ItemFrontAttribute, quantity: number): Product | boolean {
    return state.cart.forEach((element: Product) => {
      element?.ItemFrontAttributes?.forEach((attr: ItemFrontAttribute, index) => {
        if (attr.AttrId === product.AttrId) {
          const qty = attr.quantity + quantity;
          const stock = true;//this.calculateStockCounts(state.cart[index], quantity);
          if (qty !== 0 && stock) {
            attr.quantity = qty;
          }
          localStorage.setItem('cartItems', JSON.stringify(state.cart));
          return true;
        }
      }, this);
      this.cartService.cartChange(true);
    }, this);
  }
  // Calculate Stock Counts
  public calculateStockCounts(product, quantity) {
    const qty = parseInt(product.quantity) + parseInt(quantity);
    const stock = product.stock;
    if (stock < qty || stock === 0) {
      this.toastrService.error('You can not add more items than available. In stock ' + stock + ' items.');
      return false;
    }
    return true;
  }

  // Remove Cart items
  public removeCartItem(product: Product): any {
    const index = state.cart.indexOf(product);
    state.cart.splice(index, 1);    
    localStorage.setItem('cartItems', JSON.stringify(state.cart));
    this.cartService.cartChange(true);
    return true;
  }

  // Remove Cart items
  public removeAttrCartItem(product: any): any {
    state.cart.forEach((element: Product,index:number) => {
      element?.ItemFrontAttributes?.forEach((attr: ItemFrontAttribute, index) => {
        if (attr && ((attr['attrId'] || attr.AttrId) === (product['attrId'] || product['AttrId']))) {
          element.ItemFrontAttributes[index].Selected = false;
          element.ItemFrontAttributes[index].quantity = 0;
        }
      }, this);
      const iteminList=element?.ItemFrontAttributes?.some((attr)=>attr.Selected);
      if(!iteminList){
        state.cart.splice(index,1);
      }
    }, this);
    localStorage.setItem('cartItems', JSON.stringify(state.cart));
    this.cartService.cartChange(true);
    return true;
  }
  // Total amount 
  public cartTotalAmount(): Observable<number> {
    return this.cartItems.pipe(map((product: Product[]) => {
      return product.reduce((prev, curr: Product) => {
        let aitemTotal = 0;
        if (curr && curr?.ItemFrontAttributes && curr?.ItemFrontAttributes?.length === 0) {
          let price = curr.SellRate;
          let qty = curr.quantity || 1;
          if (curr.discount) {
            price = curr.SellRate - (curr.SellRate * curr.discount / 100);
          }
          aitemTotal = (prev + price * (qty || 1)) * this.Currency.price;
        } else {

          curr?.ItemFrontAttributes?.forEach((item: any) => {
            if (item && item.Selected) {
              let price = item.SellRate;
              let qty = item.quantity || 1;
              if (item.discount) {
                price = item.SellRate - (item.SellRate * item.discount / 100);
              }
              aitemTotal = aitemTotal + (price * qty) * this.Currency.price;
            }
          }, this);

        }

        return prev + aitemTotal;
      }, 0);
    }));
  }
  /*
    ---------------------------------------------
    ------------  Filter Product  ---------------
    ---------------------------------------------
  */

  // Get Product Filter
  public filterProducts(filter: any): Observable<Product[]> {

    return this.products.pipe(map(product =>
      product.filter((item: Product) => {
        if (item.ItemId === 640 || item.ItemId === 641) {
        }
        if (!filter.length) { return true; }
        const Tags = filter.some((prev) => { // Match Tags
          if (item.FilterTag) {
            if (item.FilterTag.includes(prev)) {
              return prev;
            }
          }
        });
        return Tags;
      })
    ));
  }
  public filterProductsForCustom(filter: any): Observable<Product[]> {

    return this.products.pipe(map(product =>
      product.filter((item: Product) => {
        if (!filter.length) { return true; }
        const Tags = filter.some((prev) => { // Match Tags
          if (item.FilterTag) {
            if (item.FilterTag.includes(prev)) {
              return prev;
            }
          }
        });
        return Tags;
      })
    ));
  }
  // Sorting Filter
  public sortProducts(products: Product[], payload = 'a-z'): any {

    if (payload === 'ascending') {
      return products.sort((a, b) => {
        if (a.ItemId < b.ItemId) {
          return -1;
        } else if (a.ItemId > b.ItemId) {
          return 1;
        }
        return 0;
      });
    } else if (payload === 'a-z') {
      return products.sort((a, b) => {
        if (a.ItemName < b.ItemName) {
          return -1;
        } else if (a.ItemName > b.ItemName) {
          return 1;
        }
        return 0;
      });
    } else if (payload === 'z-a') {
      return products.sort((a, b) => {
        if (a.ItemName > b.ItemName) {
          return -1;
        } else if (a.ItemName < b.ItemName) {
          return 1;
        }
        return 0;
      });
    } else if (payload === 'low') {
      return products.sort((a, b) => {
        if (a.SellRate < b.SellRate) {
          return -1;
        } else if (a.SellRate > b.SellRate) {
          return 1;
        }
        return 0;
      });
    } else if (payload === 'high') {
      return products.sort((a, b) => {
        if (a.SellRate > b.SellRate) {
          return -1;
        } else if (a.SellRate < b.SellRate) {
          return 1;
        }
        return 0;
      });
    }
    else if (payload === 'SaleQty') {
      return products.sort((a, b) => {
        if (a.SaleQty > b.SaleQty) {
          return -1;
        } else if (a.SaleQty < b.SaleQty) {
          return 1;
        }
        return 0;
      });
    }
  }

  /*
    ---------------------------------------------
    ------------- Product Pagination  -----------
    ---------------------------------------------
  */
  public getPager(totalItems: number, currentPage: number = 1, pageSize: number = 16) {
    // calculate total pages
    const totalPages = Math.ceil(totalItems / pageSize);

    // Paginate Range
    const paginateRange = 3;

    // ensure current page isn't out of range
    if (currentPage < 1) {
      currentPage = 1;
    } else if (currentPage > totalPages) {
      currentPage = totalPages;
    }

    // tslint:disable-next-line:one-variable-per-declaration
    let startPage: number, endPage: number;
    if (totalPages <= 5) {
      startPage = 1;
      endPage = totalPages;
    } else if (currentPage < paginateRange - 1) {
      startPage = 1;
      endPage = startPage + paginateRange - 1;
    } else {
      startPage = currentPage - 1;
      endPage = currentPage + 1;
    }

    // calculate start and end item indexes
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

    // create an array of pages to ng-repeat in the pager control
    const pages = Array.from(Array((endPage + 1) - startPage).keys()).map(i => startPage + i);

    // return object with all pager properties required by the view
    return {
      totalItems,
      currentPage,
      pageSize,
      totalPages,
      startPage,
      endPage,
      startIndex,
      endIndex,
      pages
    };
  }

  //CustomerEnquiry
  public setCustomerEnquiry(req: any): Observable<any> {
    return this.http.post<any[]>(this.env.apiUrl + 'api/CustomerEnquiry/CreateCustomerEnquiry',req,
      this.getheaders());
  }
/**End */
//CustomerEnquiry
public setSubscribe(req: any): Observable<any> {
  return this.http.post<any[]>(this.env.apiUrl + 'api/UserSubcribers/CreateUserSubcribers',req,
    this.getheaders());
}
/**End */
}
