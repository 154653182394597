import { Component, OnInit, Input, HostListener } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AuthService } from '../../services/auth.service';
import { CompanyService } from '../../services/company.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';
import { UtilityService } from '../../services/util.service';
import { ProductService } from '../../services/product.service';
import { Menu, NavService } from '../../services/nav.service';

@Component({
  selector: 'app-header-one',
  templateUrl: './header-one.component.html',
  styleUrls: ['./header-one.component.scss']
})
export class HeaderOneComponent implements OnInit {

  env = environment;
  code: number;
  @Input() class: string;
  @Input() themeLogo: string = 'assets/images/icon/logo.png'; // Default Logo
  @Input() topbar: boolean = true; // Default True
  @Input() sticky: boolean = false; // Default false

  public stick: boolean = false;
  public search: string;
  searchItem: string;
  public availableProduct: any;
  Header1: any;
  public menuItems: Menu[];
  public shopCategoryMenu: Menu[];

  public pages: Menu[];
  constructor(private router: Router, public navServices: NavService, public companyService: CompanyService, private cookieService: CookieService,
    public authService: AuthService, public http: HttpClient, private utilSerive: UtilityService, public productService: ProductService) {
    this.search = this.utilSerive.getParamValueQueryString('search');
    this.productService.getProducts.subscribe(response => {
      this.availableProduct = response;

    });
    this.navServices.items.subscribe(menuItems => this.menuItems = menuItems);
    this.navServices.leftMenuItems.subscribe((menuItems) => {
      this.shopCategoryMenu = menuItems; 
      this.shopCategoryMenu.forEach((f) => f.megaMenu = false);
    });

  }
  selectChange($event) {
    this.router.navigate(['/collections/all'], { queryParams: { search: $event } });
  }
  ngOnInit(): void {
    this.http.get(this.env.apiUrl + 'api/Property/GetCompanyList',
      this.getheaders()).subscribe({
        next: (res: any) => {
          if (res.Data) {
            this.code = res.Data.Hotellist_Model.ismultivendor;

          }
        }
      });
    this.companyService.geHeaderTitleMaster(1).subscribe({
      next: (res: any) => {
        if (res.Data) {
          this.Header1 = res.Data;
        }
      }
    });
  }

  // @HostListener Decorator
  @HostListener("window:scroll", [])
  onWindowScroll() {
    let number = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    if (number >= 150 && window.innerWidth > 400) {
      this.stick = true;
    } else {
      this.stick = false;
    }
  }
  remove($event) {
    this.router.navigateByUrl('/collections/all');
  }
  getheaders(): any {
    let httpOptions: any;
    if (this.cookieService.get('auth')) {
      const token = this.cookieService.get('auth');
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json; charset=utf-8',
          Authorization: ('Bearer ' + token),
        })
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json; charset=utf-8'
        })
      };
    }
    return httpOptions;
  }

  loginvendor() {
    this.http.get(this.env.apiUrl + 'api/Property/GetCompanyList',
      this.getheaders()).subscribe({
        next: (res: any) => {
          if (res.Data) {
            window.open('http://' + res.Data.Hotellist_Model.AdminUrl + '/auth/login?role=vendor&pcode=' + res.Data.Hotellist_Model.BranchCode);

          }
        }
      });

  }

}
