import { Component, OnInit, Input } from '@angular/core';
import { environment } from 'src/environments/environment';
import { CompanyService } from '../../services/company.service';
import { NavService } from '../../services/nav.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ProductService } from '../../services/product.service';
const phoneEmailRegex = /^(\d{10}|\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3}))$/;
@Component({
  selector: 'app-footer-one',
  templateUrl: './footer-one.component.html',
  styleUrls: ['./footer-one.component.scss']
})
export class FooterOneComponent implements OnInit {
env=environment;
  @Input() class: string = 'footer-light' // Default class 
  @Input() themeLogo: string = 'assets/images/icon/logo.png' // Default Logo
  @Input() newsletter: boolean = true; // Default True

  public today: number = Date.now();
  public footerPageMenuItems:any;
  public footerCategory:any;
  Header2:any; Header1:any;
  subscribeForm:FormGroup;
  constructor(public companyService:CompanyService,private navServices:NavService,private toast:ToastrService,public productService: ProductService,private fb:FormBuilder) {
    this.navServices.footerPageMenuItems.subscribe(menuItems => this.footerPageMenuItems = menuItems );
    this.navServices.leftMenuItems.subscribe(menuItems => this.footerCategory = menuItems );
   }

  ngOnInit(): void {
    this.companyService.geHeaderTitleMaster(5).subscribe({
      next: (res: any) => {
        if (res.Data) {
          this.Header2=res.Data;
        }
      }});

      this.companyService.geHeaderTitleMaster(4).subscribe({
        next: (res: any) => {
          if (res.Data) {
            this.Header1=res.Data;
          }
        }});

        this.subscribeForm = this.fb.group({
          SubscriberId: [0, []],
          Input: ['', [Validators.required, Validators.pattern(phoneEmailRegex)]]
        });
  }
  submitSubscribe(){
    if(!this.subscribeForm.valid){
      return ;
    }    
    this.productService.setSubscribe(this.subscribeForm.value).subscribe({
      next: (res: any) => {
        if (res.Status===0) {
          this.subscribeForm.patchValue({Input:''});
          this.toast.success('Subscribe Sumbitted Sucessfully','Success');

        }else{
          this.toast.error(res.Message||'Error in Adding Subscribe','Error');
        }
      }
    });
  }
}
