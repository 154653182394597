import { AfterContentInit, ContentChildren, Directive, HostListener, QueryList } from '@angular/core';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
@Directive({
  selector: '[appNestedDropdown]',
})
export class NestedDropdownDirective implements AfterContentInit {

  @ContentChildren(NgbDropdown, { descendants: true })
  childrenDropdowns: QueryList<NgbDropdown>;

  private eventListeners: { element: HTMLElement; eventName: string; callback: (event: any) => any }[] = [];

  constructor(private parentDropdown: NgbDropdown) {
  }

  @HostListener('window:click', ['$event'])
  windowClick(event: Event) {
    if (!(event.target instanceof HTMLElement) || !(event.target as HTMLElement).closest('.dropdown, .dropdown-toggle')) {
      this.parentDropdown.close();
    }
  }

  mouseLeaveSection(event: MouseEvent, dropdown: NgbDropdown) {
    // eslint-disable-next-line @typescript-eslint/dot-notation
    const index = dropdown['__nestedDropdownIndex'];
    const element = event.relatedTarget as HTMLElement;
    if (!(element instanceof HTMLElement) || !(element.closest(`.nested-dropdown-section-${index}`))) {
      dropdown.close();
    }
  }

  ngAfterContentInit() {
    // eslint-disable-next-line @typescript-eslint/dot-notation
    this.parentDropdown['_menu']['nativeElement'].classList.add('nested-dropdown-parent-menu');
    this.parentDropdown.openChange.subscribe(() => {
      this.childrenDropdowns.forEach(dropdown => dropdown.close());
    });
    this.childrenDropdowns.changes.subscribe(() => this.updateChildrenDropdowns());
    this.updateChildrenDropdowns();
  }

  private removePreviousEventListenerAndAddOne(element: HTMLElement, eventName: string, callback: (event: any) => any) {
    const existingListeners = this.eventListeners.filter(listener => listener.element === element && listener.eventName === eventName);
    existingListeners.forEach(listener => listener.element.removeEventListener(listener.eventName, listener.callback));
    _.pullAll(this.eventListeners, existingListeners);
    element.addEventListener(eventName, callback);
    this.eventListeners.push({ element, eventName, callback });
  }

  private updateChildrenDropdowns() {
    this.childrenDropdowns.forEach((dropdown, index) => {
      // eslint-disable-next-line @typescript-eslint/dot-notation
      const dropdownElement = dropdown['_elementRef'].nativeElement;
      // eslint-disable-next-line @typescript-eslint/dot-notation
      const menuElement: HTMLElement = dropdown['_menu']['nativeElement'];
      const toggleElement: HTMLElement = dropdownElement.querySelector('.dropdown-toggle');

      dropdown.autoClose = false;
      dropdown.placement = ['right-top', 'right-bottom', 'left-top', 'left-bottom'];
      // eslint-disable-next-line @typescript-eslint/dot-notation
      dropdown['__nestedDropdownIndex'] = index;

      toggleElement.classList.add('dropdown-toggle-without-arrow');
      menuElement.classList.add('nested-dropdown-child-menu');
      [toggleElement, menuElement].forEach(element => {
        Array.from(element.classList).forEach(className => {
          if (className.match(/^nested-dropdown-section-\d+$/)) {
            element.classList.remove(className);
          }
        });
        element.classList.add(`nested-dropdown-section-${index}`);
      });

      this.removePreviousEventListenerAndAddOne(toggleElement, 'mouseenter', () => dropdown.open());
      this.removePreviousEventListenerAndAddOne(toggleElement, 'mouseleave', event => this.mouseLeaveSection(event, dropdown));
      this.removePreviousEventListenerAndAddOne(menuElement, 'mouseleave', event => this.mouseLeaveSection(event, dropdown));
    });
  }

}