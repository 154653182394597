import { Component, OnInit } from '@angular/core';
import { NavService, Menu } from '../../services/nav.service';
import { Router } from '@angular/router';
import { CompanyService } from '../../services/company.service';
import { ProductService } from '../../services/product.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {

  public menuItems: Menu[];
  public shopCategoryMenu: Menu[];

  public pages: Menu[];
  public VariationFlatList=[];
  constructor(private router: Router, public navServices: NavService, public companyService: CompanyService,public productService:ProductService) {
    this.navServices.items.subscribe(menuItems => this.menuItems = menuItems);
    this.navServices.leftMenuItems.subscribe((menuItems) => {
      this.shopCategoryMenu = menuItems;
      this.shopCategoryMenu.forEach((f) => f.megaMenu = false);
    });
    this.companyService.companyPages.subscribe(pages => this.pages = pages);
    this.router.events.subscribe((event) => {
      this.navServices.mainMenuToggle = false;
    });
    this.productService.getProductsVariation.subscribe(varitaions => {
      let arrayList=[]
      varitaions.forEach((element:any) => {
         if(element.Child){
          arrayList.push(element?.Child);
         }
       });
       this.VariationFlatList = [...new Set(arrayList.flat())];
    });
  }

  ngOnInit(): void {
  }
  onHover(menuItem) {
    if (window.innerWidth > 1200 && menuItem) {
      document.getElementById('unset').classList.add('sidebar-unset')
    } else {
      document.getElementById('unset').classList.remove('sidebar-unset')
    }
  }

  mainMenuToggle(): void {
    this.navServices.mainMenuToggle = !this.navServices.mainMenuToggle;
  }

  // Click Toggle menu (Mobile)
  toggletNavActive(item) {
    item.active = !item.active;
  }

}
