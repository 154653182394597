<owl-carousel-o [options]="HomeSliderConfig" class="home-slider footer-slider" [ngClass]="class">
	<ng-container *ngFor="let slider of SliderList">
	  <ng-template carouselSlide>
	    <div class="footer" [ngClass]="textClass" [ngStyle]="{'background-image': 'url('+env.mediaUrl+slider.ImageName+')'}">
	      <div class="container">
	        <div class="row">
	          <div class="col">
	            <div class="slider-contain">
	              <div>
	                <h4 translate>{{slider.title | translate}}</h4>
	                <h1 translate>{{slider.subTitle | translate}}</h1>
	                <a [routerLink]="['/collection/all']" 
	                   class="btn btn-solid" [ngClass]="buttonClass">
	                   {{buttonText | translate}}
	                </a>
	              </div>
	            </div>
	          </div>
	        </div>
	      </div>
	    </div>
	  </ng-template>
	</ng-container>
</owl-carousel-o>