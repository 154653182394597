<div class="img-wrapper">
    <div class="lable-block" *ngIf="!product.ImgName"><span class="lable3">new</span></div>
    <div class="front">
        <a [routerLink]="['/products/', product.ItemName.replace(' ', '-')]">
            <img  [defaultImage]="'assets/images/product/placeholder.jpg'"
                  [lazyLoad]="product.ImgName?.src" class="img-fluid"
                  alt="{{ product.ImgName?.alt }}">
        </a>
    </div>
    <div class="cart-box">
        <button title="Add to cart" (click)="CartModal.openModal(product)" *ngIf="cartModal">
        	<i class="ti-shopping-cart"></i> 
        </button>
        <button title="Add to cart" (click)="addToCart(product)" *ngIf="!cartModal">
        	<i class="ti-shopping-cart"></i> 
        </button>
        <a href="javascript:void(0)" (click)="addToWishlist(product)" title="Add to Wishlist">
        	<i class="ti-heart" aria-hidden="true"></i>
        </a>
        <a href="javascript:void(0)" class="mobile-quick-view" (click)="QuickView.openModal()" title="Quick View">
        	<i class="ti-search" aria-hidden="true"></i>
        </a>
        <a href="javascript:void(0)" (click)="addToCompare(product)" title="Compare">
        	<i class="ti-reload" aria-hidden="true"></i>
        </a>
    </div>
    <!-- <div class="quick-view-part">
        <a href="javascript:void(0)" (click)="QuickView.openModal()" title="Quick View">
        	<i class="ti-search" aria-hidden="true"></i>
        </a>
    </div> -->
</div>
<div class="product-info">
    <bar-rating [rate]="5" [readOnly]="true"></bar-rating>
    <a [routerLink]="['/products/', product?.ItemName.replace(' ', '-')]">
    	<h6 translate>{{ product?.ItemName | titlecase }}</h6>
    </a>
    <h4 translate>{{ product?.SellRate * currency?.price | discount:product | currency:currency?.currency:'symbol' }}</h4>
</div>

<app-quick-view #quickView [product]="product" [currency]="currency"></app-quick-view>
<app-cart-modal #cartModal [product]="product" [currency]="currency" *ngIf="cartModal"></app-cart-modal>